const state = () => ({
  tabList: [],
  tabIndex: 1,
  errors: {},
  stage: 1,
  transactionType: 'retail',
  pic: {
    id: null,
    name: '',
    code: '',
    hasPic: false,
  },
  member: {
    id: null,
    name: '',
    code: '',
    coin: 0,
  },
  items: [],
  returs: {
    id: null,
    invoiceNumber: '',
    details: [],
  },
  discount: {
    percentage: 0,
    nominal: 0,
  },
  extras: [],
  shipping: null,
  payments: [],
  coinPayment: 0,
})

const getters = {
  getStage (state) {
    return state.stage
  },
  getErrors (state) {
    return state.errors
  },
  getTransactionType (state) {
    return state.transactionType
  },
  isTransactionTypeRetail (state) {
    return state.transactionType === 'retail'
  },
  getPic (state) {
    return state.pic
  },
  getMember (state) {
    return state.member
  },
  getDiscount (state) {
    return state.discount
  },

  // tab view
  getTabIndex (state) {
    return state.tabIndex
  },
  getTabList (state) {
    return state.tabList
  },
  getVariables (state) {
    return {
      stage: state.stage,
      transactionType: state.transactionType,
      pic: state.pic,
      member: state.member,
      items: state.items,
      returs: state.returs,
      discount: state.discount,
      extras: state.extras,
      shipping: state.shipping,
      payments: state.payments,
      coinPayment: state.coinPayment,
    }
  },

  // purchase item
  getItems (state) {
    return state.items
  },
  getItemByItem: (state) => (index) => {
    if (index !== null) {
      return state.items[index]
    }
    return null
  },
  getTotalPurchase (state) {
    let total = 0
    state.items.forEach(item => {
      total += (item.price - item.discountNominal) * item.qty
    })
    return total
  },
  getTotalItem (state) {
    let total = 0
    state.items.forEach(item => {
      if (item.price > 0) {
        total += parseInt(item.qty)
      }
    })
    return total
  },
  getTotalFreeItem (state) {
    let total = 0
    state.items.forEach(item => {
      if (item.price === 0) {
        total += parseInt(item.qty)
      }
    })
    return total
  },

  //retur item
  getReturs (state) {
    return state.returs
  },
  getTotalReturPrice (state) {
    let total = 0
    state.returs.details.forEach(detail => {
      total += detail.qtyRetur * detail.price
    })
    return total
  },
  getTotalRetur (state) {
    let total = 0
    state.returs.details.forEach(detail => {
      total += detail.qtyRetur
    })
    return total
  },

  //extra item
  getExtraItems (state) {
    return state.extras
  },
  getExtraCount (state) {
    return state.extras.length
  },
  getTotalExtraItemCost (state) {
    let total = 0
    state.extras.forEach(element => {
      total += element.cost
    })
    return total
  },

  //discount
  getDiscountNominal (state) {
    return parseInt(state.discount.nominal)
  },
  getDiscountPercentage (state) {
    return parseInt(state.discount.percentage)
  },

  //shipping
  getShippingInformation (state) {
    return state.shipping
  },

  //payments
  getPayments (state) {
    return state.payments
  },
  getPaymentByIndex: (state) => (index) => {
    return state.payments[index]
  },
  getTotalPayment (state) {
    let total = 0
    state.payments.forEach(element => {
      total += element.nominal ? parseInt(element.nominal) : 0
    })
    return total
  },
  getCoinPayment (state) {
    return state.coinPayment ? parseInt(state.coinPayment) : 0
  },
}

const actions = {
  initVariables ({ commit }, params) {
    commit('setVariables', params)
  },
  resetCurrentVariables ({ commit }) {
    commit('resetVariables')
  },
  initTabList ({ commit }, list) {
    commit('setTabList', list)
  },
  addNewTabList ({ commit }) {
    commit('addTabList')
    commit('resetVariables')
  },
  removeTabList ({ commit }, index) {
    commit('deleteTabListByIndex', index)
  },
  changeTabIndex ({ commit }, index) {
    commit('setTabIndex', index)
    commit('setErrors', {})
  },
  changeStage ({ commit }, stage) {
    commit('setStage', stage)
    commit('setErrors', {})
  },
  changeErrors ({ commit }, errors) {
    commit('setErrors', errors)
  },
  changeTransactionType ({ commit }, type) {
    commit('setTransactionType', type)
  },
  addPic ({ commit }, pic) {
    commit('inputPic', pic)
  },
  removePic ({ commit }) {
    commit('resetPic')
  },
  addMember ({ commit }, member) {
    commit('inputMember', member)
  },
  removeMember ({ commit }) {
    commit('resetMember')
  },
  addDiscount ({ commit }, discount) {
    commit('inputDiscount', discount)
  },
  updateDiscount ({ state, commit, getters }) {
    if (state.discount.percentage > 0) {
      const discount = {
        percentage: state.discount.percentage,
        nominal: parseFloat(getters.getTotalPurchase * state.discount.percentage / 100),
      }
      commit('inputDiscount', discount)
    }
  },
  addNewItem ({ commit }, newItem) {
    commit('addItem', newItem)
  },
  increaseItemQty ({ commit }, index) {
    commit('updateQty', { index: index, qty: 1 })
  },
  decreaseItemQty ({ state, commit }, index) {
    if (state.items[index].qty > 1) {
      commit('updateQty', { index: index, qty: -1 })
    }
  },
  updateDiscountItem ({ commit }, params) {
    commit('updateDiscountItem', params)
  },
  deleteItem ({ commit }, index) {
    commit('deleteItemByIndex', index)
  },
  addNewRetur ({ commit }, newRetur) {
    commit('addRetur', newRetur)
  },
  deleteReturItem ({ commit }, index) {
    commit('deleteReturByIndex', index)
  },
  deleteRetur ({ commit }) {
    commit('deleteReturs')
  },
  addNewExtraItem ({ commit }, newExtraItem) {
    commit('addExtraItem', newExtraItem)
  },
  deleteExtraItem ({ commit }, index) {
    commit('deleteExtraItemByIndex', index)
  },
  addShippingInformation ({ commit }, shipping) {
    commit('setShipping', shipping)
  },
  deleteShippingInformation ({ commit }) {
    commit('deleteShipping')
  },
  addNewPayment ({ commit }, param) {
    commit('addPayment', param)
  },
  updatePaymentByIndex ({ commit }, param) {
    commit('updatePayment', param)
  },
  deletePaymentByIndex ({ commit }, index) {
    commit('deletePayment', index)
  },
  deleteAllPayments ({ commit }) {
    commit('deletePayments')
  },
  updateCoinPayment ({ commit }, nominal) {
    commit('setCoinPayment', nominal)
  },
  transactionSuccess ({ commit }) {
    commit('resetVariables')
    commit('setErrors', {})
    commit('setStage', 1)
  },
}

const mutations = {
  setVariables (state, params) {
    state.stage = params.stage
    state.transactionType = params.transactionType
    state.pic = params.pic
    state.member = params.member
    state.items = params.items
    state.returs = params.returs
    state.discount = params.discount
    state.extras = params.extras
    state.shipping = params.shipping
    state.payments = params.payments
    state.coinPayment = params.coinPayment
  },
  resetVariables (state) {
    state.stage = 1
    state.errors = {}
    state.transactionType = 'retail'
    state.pic = {
      id: null,
      name: '',
      code: '',
      hasPic: false,
    }
    state.member = {
      id: null,
      name: '',
      code: '',
      coin: 0,
    }
    state.items = []
    state.returs = {
      id: null,
      invoiceNumber: '',
      details: [],
    }
    state.discount = {
      percentage: 0,
      nominal: 0,
    }
    state.extras = []
    state.shipping = null
    state.payments = []
    state.coinPayment = 0
  },
  setTabList (state, list) {
    state.tabList = list
    state.tabIndex = state.tabList[0].index
  },
  addTabList (state) {
    let lastIndex = state.tabList[state.tabList.length - 1].index + 1
    state.tabList.push({
      index: lastIndex,
      formName: 'form-' + lastIndex,
    })
    state.tabIndex = lastIndex
  },
  deleteTabListByIndex (state, index) {
    state.tabList.splice(index, 1)
    state.tabIndex = state.tabList[0].index
  },
  setTabIndex (state, index) {
    state.tabIndex = index
  },
  setStage (state, stage) {
    state.stage = stage
  },
  setErrors (state, errors) {
    state.errors = errors
  },
  setTransactionType (state, type) {
    state.transactionType = type
  },
  inputPic (state, pic) {
    state.pic = pic
  },
  resetPic (state) {
    state.pic = {
      id: null,
      name: '',
      code: '',
      hasPic: false,
    }
  },
  inputMember (state, member) {
    state.member = member
  },
  resetMember (state) {
    state.member = {
      id: null,
      name: '',
      code: '',
      coin: 0,
      hasMember: false,
    }
  },
  inputDiscount (state, discount) {
    state.discount = discount
  },
  addItem (state, newItem) {
    if (state.items.filter(item => item.id === newItem.id || item.productCode === newItem.productCode).length) {
      state.items.filter(item => item.id === newItem.id || item.productCode === newItem.productCode)[0].qty++
    } else {
      state.items.push(newItem)
    }
  },
  updateQty (state, params) {
    state.items[params.index].qty += params.qty
  },
  updateDiscountItem (state, params) {
    state.items[params.index].discountNominal = params.nominal
    state.items[params.index].discountPercentage = params.percentage
  },
  deleteItemByIndex (state, index) {
    state.items.splice(index, 1)
  },
  addRetur (state, newRetur) {
    state.returs = newRetur
  },
  deleteReturByIndex (state, index) {
    state.returs.details[index].qtyRetur = 0
    if (state.returs.details.filter(element => element.qtyRetur > 0).length === 0) {
      state.returs.id = null
      state.returs.invoiceNumber = ''
      state.returs.details = []
    }
  },
  deleteReturs (state) {
    state.returs = {
      id: null,
      invoiceNumber: '',
      details: [],
    }
  },
  addExtraItem (state, newExtraItem) {
    state.extras.push(newExtraItem)
  },
  deleteExtraItemByIndex (state, index) {
    state.extras.splice(index, 1)
  },
  setShipping (state, shipping) {
    state.shipping = shipping
  },
  deleteShipping (state) {
    state.shipping = null
  },
  addPayment (state, param) {
    state.payments.push({
      name: param?.paymentMethod?.label ?? 'Pilih Metode',
      nominal: param.nominal ?? 0,
      senderAccountName: '',
      senderAccountNumber: '',
      receiverAccountName: '',
      receiverAccountNumber: '',
      note: '',
      paymentMethodId: param?.paymentMethod?.id,
      edcMachineId: null,
    })
  },
  deletePayment (state, index) {
    state.payments.splice(index, 1)
  },
  deletePayments (state) {
    state.payments = []
  },
  setCoinPayment (state, nominal) {
    state.coinPayment = nominal
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}