<script setup>
import { computed, inject, ref, nextTick, watch, onMounted } from 'vue'
import Cleave from 'cleave.js'

const fmt = inject('fmt')
const emit = defineEmits(['update:modelValue', 'focus', 'change'])
const props = defineProps({
  prefix: {
    type: String,
    default: null,
  },
  suffix: {
    type: String,
    default: null,
  },
  modelValue: {
    type: [String, Number],
    required: true,
  },
  placeholder: {
    type: String,
    default: null,
  },
  max: {
    type: Number,
    default: null,
  },
  errorForm: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})
const cleave = ref(null)
const gNumber = ref(null)
const numberInput = computed({
  set (newValue) {
    const currentVal = cleave.value ? cleave.value.getRawValue() : newValue
    if (props.max !== null && currentVal > props.max) {
      emit('update:modelValue', -1)
      nextTick(function () {
        emit('update:modelValue', props.max)
      })
      return
    }
    emit('update:modelValue', Number(currentVal))
  },
  get () {
    return fmt.number(props.modelValue)
  },
})
watch(() => numberInput.value, () => {
  const val = cleave.value.getRawValue()
  emit('change', isNaN(val) ? 0 : Number(val))
})

onMounted(() => {
  nextTick(() => {
    cleave.value = new Cleave(gNumber.value, {
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
      numeralDecimalMark: ',',
      delimiter: '.',
      numericOnly: true,
      numeralDecimalScale: 2,
    })
  })
})
</script>
<template>
  <div class="relative">
    <div v-if="prefix" class="absolute inset-y-0 flex items-center justify-center text-gray-200 left-4">
      {{ prefix }}
    </div>
    <input
      ref="gNumber"
      v-model="numberInput"
      class="w-full form-input text-right"
      :class="{'pl-14': prefix, 'pr-12': suffix, 'form-error': errorForm}"
      :placeholder="placeholder"
      :disabled="disabled"
      type="text"
      @focus="emit('focus', $event)">
    <div v-if="suffix" class="absolute inset-y-0 flex items-center justify-center text-gray-200 right-2">
      {{ suffix }}
    </div>
  </div>
</template>