<script setup>
import { computed, inject, ref } from 'vue'
import EmptyResult from '@/components/Placeholder/EmptyResult.vue'
import EmptyData from '@/components/Placeholder/EmptyData.vue'

const props = defineProps({
  headColumns: {
    type: Array,
    required: true,
  },
  dataList: {
    type: Array,
    default: () => {},
  },
  loaded: {
    type: Boolean,
    default: false,
  },
  meta: {
    type: Object,
    default: () => {},
  },
  showNumber: {
    type: Boolean,
    default: false,
  },
  selection: {
    type: Boolean,
    default: false,
  },
})

const route = inject('route')
const pagination = ref(null)
const param = computed(() => route.query)

const checkAll = () => {
  const check = !(props.dataList.filter(item => item.check).length == props.dataList.length)
  props.dataList.forEach(item => {
    item.check = check
  })
}
const clearSelected = () => {
  props.dataList.forEach(item => {
    item.check = false
  })
}
defineExpose({ clearSelected })
</script>

<template>
  <div class="rounded flex flex-col h-full overflow-auto">
    <table class="paginated-table">
      <thead class="head sticky top-0">
        <tr>
          <template v-if="showNumber">
            <th class="text-center content w-8">No</th>
          </template>
          <template v-if="selection">
            <th class="text-center content">
              <button class="text-white focus:outline-none" @click="checkAll(!(dataList.filter(item => item.check).length == dataList.length))">
                <fa-icon :icon="['far', dataList.filter(item => item.check).length == dataList.length && dataList.length > 0 ? 'square-check' : 'square']" size="lg"/>
              </button>
            </th>
          </template>
          <template v-for="column in headColumns" :key="column">
            <th class="content" :class="column.class">{{ column.name }}</th>
          </template>
        </tr>
      </thead>
      <tbody>
        <template v-if="loaded">
          <template v-if="dataList.length > 0">
            <template v-for="(item, index) in dataList" :key="index">
              <tr>
                <template v-if="showNumber">
                  <td class="text-center content w-8">{{ index+1 }}</td>
                </template>
                <template v-if="selection">
                  <td class="text-center content">
                    <button class="focus:outline-none" :class="item.check ? 'text-blue-500' : 'text-gray-500'" @click="item.check = !item.check">
                      <fa-icon :icon="['far', item.check ? 'square-check' : 'square']" size="lg"/>
                    </button>
                  </td>
                </template>
                <template v-for="(data, indexData) in item.data" :key="indexData">
                  <td class="content" :class="data.class" :colspan="data.colspan ?? 0">
                    <template v-if="data.type=='date'">
                      {{ fmt.date(new Date(data.value), 'd MMM yyyy') }}
                    </template>
                    <template v-else-if="data.type=='number'">
                      {{ fmt.number(data.value) }}
                    </template>
                    <template v-else-if="data.type=='decimal'">
                      {{ fmt.decimal(data.value) }}
                    </template>
                    <template v-else-if="data.type=='price'">
                      {{ fmt.price(data.value) }}
                    </template>
                    <template v-else>
                      <slot
                        :data-id="item.id"
                        :item="data"
                        :index="index"
                        :name="'columnData'+indexData">
                        {{ data.value }}
                      </slot>
                    </template>
                  </td>
                </template>
              </tr>
            </template>
          </template>
          <template v-else>
            <tr>
              <td class="content" :colspan="headColumns.length + (showNumber) + (selection) ">
                <template v-if="param && !(Object.keys(param).length === 0)">
                  <EmptyResult />
                </template>
                <template v-else>
                  <EmptyData />
                </template>
              </td>
            </tr>
          </template>
          <tr>
            <td class="pagination sticky bottom-0" :colspan="headColumns.length + (showNumber) + (selection) ">
              <pagination-data ref="pagination" :value="meta"/>
            </td>
          </tr>
        </template>
        <template v-else>
          <template v-for="n in 9" :key="n">
            <tr>
              <td class="content w-full h-6 loading" :colspan="headColumns.length + (showNumber) + (selection) "></td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </div>
</template>

<style lang="postcss" scoped>
.paginated-table {
  @apply table-auto w-full relative;
  .head th {
    @apply text-white bg-blue-300 px-2 py-4;
  }
  .head th:first-child {
    @apply pl-4 pr-2;
  }
  .head th:last-child {
    @apply pr-4 pl-2;
  }
  tr td:first-child {
    @apply pl-4 pr-2;
  }
  tr td:last-child {
    @apply pr-4 pl-2;
  }
  /* top-left border-radius */
  tr:first-child th:first-child {
    @apply rounded-tl;
  }
  /* top-right border-radius */
  tr:first-child th:last-child {
    @apply rounded-tr;
  }
  /* bottom-left border-radius */
  tr:last-child td:first-child {
    @apply rounded-bl;
  }
  /* bottom-right border-radius */
  tr:last-child td:last-child {
    @apply rounded-br;
  }
  th,
  td {
    @apply border-y border-blue-100 border-opacity-50  whitespace-nowrap;
  }
  .content {
    @apply p-2 bg-white;
  }
  .pagination {
    @apply bg-white;
  }
}
</style>