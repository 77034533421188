import { format, isValid } from 'date-fns/esm'
import { id } from 'date-fns/esm/locale'

export default {
  date: (dt, fmt) => { // format : 13 Jan 2020
    if (!dt) return '-'

    dt = isValid(dt) ? dt : new Date(dt)

    return format(dt, fmt, { locale: id })
  },
}

