// https://www.npmjs.com/package/@fortawesome/vue-fontawesome

import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faArrowRight,
  faArrowRightArrowLeft,
  faArrowRotateLeft,
  faArrowRotateRight,
  faBallotCheck,
  faBars,
  faBell,
  faBellOn,
  faBellSlash,
  faBold,
  faBoxCheck,
  faBoxCircleCheck,
  faBoxesStacked,
  faBracketSquare,
  faCalendarDays,
  faCartPlus,
  faCashRegister,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleQuestion,
  faCircleXmark,
  faClipboard,
  faClockRotateLeft,
  faEnvelope,
  faEnvelopeOpen,
  faEye,
  faEyeSlash,
  faFaceSadSweat,
  faFileChartColumn,
  faFileInvoiceDollar,
  faFileSpreadsheet,
  faFilterList,
  faFloppyDiskCircleArrowRight,
  faGauge,
  faH1,
  faHandHoldingDollar,
  faHorizontalRule,
  faIdCard,
  faItalic,
  faLightbulbExclamationOn,
  faListOl,
  faListUl,
  faLockKeyhole,
  faMagnifyingGlass,
  faMoneyBillWave,
  faPaperPlane,
  faPenToSquare,
  faPlus,
  faPrint,
  faReceipt,
  faRotate,
  faSignal,
  faSlash,
  faSquare,
  faSquareCheck,
  faStrikethrough,
  faTag,
  faTags,
  faToggleOff,
  faToggleOn,
  faTrash,
  faTruck,
  faTruckFast,
  faTruckMoving,
  faTruckRampBox,
  faUnlockKeyhole,
  faUser,
  faUserLock,
  faUsers,
  faUserTie,
  faWarehouse,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons'

library.add(
  faArrowRight,
  faArrowRightArrowLeft,
  faArrowRotateLeft,
  faArrowRotateRight,
  faBallotCheck,
  faBars,
  faBell,
  faBellOn,
  faBellSlash,
  faBold,
  faBoxCheck,
  faBoxCircleCheck,
  faBoxesStacked,
  faBracketSquare,
  faCalendarDays,
  faCartPlus,
  faCashRegister,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleQuestion,
  faCircleXmark,
  faClipboard,
  faClockRotateLeft,
  faEnvelope,
  faEnvelopeOpen,
  faEye,
  faEyeSlash,
  faFaceSadSweat,
  faFileChartColumn,
  faFileInvoiceDollar,
  faFileSpreadsheet,
  faFilterList,
  faFloppyDiskCircleArrowRight,
  faGauge,
  faH1,
  faHandHoldingDollar,
  faHorizontalRule,
  faIdCard,
  faItalic,
  faLightbulbExclamationOn,
  faListOl,
  faListUl,
  faLockKeyhole,
  faMagnifyingGlass,
  faMoneyBillWave,
  faPaperPlane,
  faPenToSquare,
  faPlus,
  faPrint,
  faReceipt,
  faRotate,
  faSignal,
  faSlash,
  faSquare,
  faSquareCheck,
  faStrikethrough,
  faTag,
  faTags,
  faToggleOff,
  faToggleOn,
  faTrash,
  faTruck,
  faTruckFast,
  faTruckMoving,
  faTruckRampBox,
  faUnlockKeyhole,
  faUser,
  faUsers,
  faUserTie,
  faUserLock,
  faWarehouse,
  faXmark,
)

import {
  faCartFlatbedBoxes,
  faCircle as faCircleSolid,
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faCircleXmark as faCircleXmarkSolid,
  faExclamation,
  faShop as faShopSolid,
  faShopLock,
  faStore as faStoreSolid,
  faReply,
} from '@fortawesome/pro-solid-svg-icons'
library.add(
  faCartFlatbedBoxes,
  faCircleExclamation,
  faCircleInfo,
  faCircleSolid,
  faCircleCheck,
  faCircleXmarkSolid,
  faExclamation,
  faShopSolid,
  faShopLock,
  faStoreSolid,
  faReply,
)

export default {
  install (Vue) {
    Vue.component('FaIcon', FontAwesomeIcon)
    Vue.component('FaLayers', FontAwesomeLayers)
    Vue.component('FaLayersText', FontAwesomeLayersText)
  },
}