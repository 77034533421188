export default function () {
  let clickOutsideEvent = () => {}
  const created = function (el, binding) {
    clickOutsideEvent = function (event) {
      // check if click is outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // call method provided in attribute value
        if (typeof binding.value === 'function') {
          binding.value(event)
        }
      }
    }
    setTimeout(() => {
      window.addEventListener('mousedown', clickOutsideEvent)
    }, 20)
  }
  const unmounted = function () {
    window.removeEventListener('mousedown', clickOutsideEvent)
  }
  return {
    created,
    unmounted,
  }
}