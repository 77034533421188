<script setup>
import { provide } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import Api from './functions/api.js'
import formatter from './functions/formatters'
import RandomGenerator from './functions/randomGenerator'
import Pusher from 'pusher-js'

const router = useRouter()
const route = useRoute()
const store = useStore()
const api = new Api(router, store)
const randGenerator = new RandomGenerator()
const pusher = new Pusher(import.meta.env.PUSHER_APP_KEY, {
  cluster: import.meta.env.PUSHER_APP_CLUSTER,
  encrypted: true,
})

const initCsrfToken = () => {
  api.GET('/sanctum/csrf-cookie')
}

provide('router', router)
provide('route', route)
provide('store', store)
provide('api', api)
provide('fmt', formatter)
provide('pusher', pusher)
provide('randGenerator', randGenerator)

initCsrfToken()
</script>

<template>
  <router-view />
</template>
