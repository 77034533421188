export default [
  {
    path: '/account',
    component: () => import(/* webpackChunkName: "account-" */ '../layouts/Account/Index.vue'),
    children: [
      {
        path: 'profile',
        name: 'Profile',
        meta: { title: 'Profil' },
        component: () => import(/* webpackChunkName: "account--profile" */ '../views/Account/Profile.vue'),
      },
      {
        path: 'notification',
        name: 'Notification',
        meta: { title: 'Notifikasi' },
        component: () => import(/* webpackChunkName: "account-notification" */ '../views/Account/Notification.vue'),
      },
      {
        path: 'change-password',
        name: 'UpdatePassword',
        meta: { title: 'Ubah Password' },
        component: () => import(/* webpackChunkName: "account-change-password" */ '../views/Account/UpdatePassword.vue'),
      },
      {
        path: 'manage-store',
        name: 'ManageStore',
        meta: { title: 'Pengaturan Toko' },
        component: () => import(/* webpackChunkName: "account-manage-store" */ '../views/Account/ManageStore.vue'),
      },
      {
        path: 'set-branch-password',
        name: 'SetBranchPassword',
        meta: { title: 'Set Password Cabang' },
        component: () => import(/* webpackChunkName: "account-update-branch-password" */ '../views/Account/UpdateBranchPassword.vue'),
      },
    ],
  },
]