export default [
  {
    path: '/customer',
    children: [
      {
        path: '',
        name: 'CustomerIndex',
        meta: { title: 'Customer' },
        component: () => import(/* webpackChunkName: "customer-index" */ '../views/Customer/Index.vue'),
      },
      {
        path: ':customer_id',
        name: 'CustomerView',
        meta: { title: 'Customer - Tampilkan Customer' },
        component: () => import(/* webpackChunkName: "customer-view" */ '../views/Customer/View.vue'),
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "form-layout" */ '../layouts/Dashboard/Form.vue'),
        children: [
          {
            path: 'create',
            name: 'CustomerCreate',
            meta: { title: 'Customer - Tambah Customer' },
            component: () => import(/* webpackChunkName: "customer-create" */ '../views/Customer/Form.vue'),
          },
          {
            path: ':customer_id/edit',
            name: 'CustomerEdit',
            meta: { title: 'Customer - Ubah Customer' },
            component: () => import(/* webpackChunkName: "customer-edit" */ '../views/Customer/Form.vue'),
          },
        ],
      },
    ],
  },
]