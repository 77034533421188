export default {
  number: num => {
    if (num && !isNaN(num)) {
      return new Intl.NumberFormat('id-ID').format(num)
    }

    return '0'
  },

  price: num => {
    if (num !== null && !isNaN(num)) {
      return new Intl.NumberFormat('id-ID', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(num)
    }

    return 'Belum ada harga'
  },

  decimal: num => {
    if (num && !isNaN(num)) {
      return new Intl.NumberFormat('id-ID', { minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(num)
    }

    return '0'
  },

  numberAbbreviate: num => {
    return num
  },
}