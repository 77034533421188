export default [
  {
    path: '/stock',
    name: 'StockIndex',
    meta: { title: 'Stok Barang' },
    component: () => import(/* webpackChunkName: "stock-index" */ '../views/Product/Stock.vue'),
  },
  {
    path: '/stock/search',
    name: 'StockSearch',
    meta: { title: 'Cari Stok' },
    component: () => import(/* webpackChunkName: "stock-index" */ '../views/Product/Search.vue'),
  },
  {
    path: '/stock-adjustment',
    children: [
      {
        path: '',
        name: 'StockAdjustmentIndex',
        meta: { title: 'Penyesuaian Stok' },
        component: () => import(/* webpackChunkName: "stock-adjustment-index" */ '../views/StockAdjustment/Index.vue'),
      },
      {
        path: ':stock_adjustment_id',
        name: 'StockAdjustmentView',
        meta: { title: 'Penyesuaian Stok - Tampilkan Penyesuaian Stok' },
        component: () => import(/* webpackChunkName: "stock-adjustment-view" */ '../views/StockAdjustment/View.vue'),
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "form-layout" */ '../layouts/Dashboard/Form.vue'),
        children: [
          {
            path: 'create',
            name: 'StockAdjustmentCreate',
            meta: { title: 'Penyesuaian Stok - Tambah Penyesuaian Stok' },
            component: () => import(/* webpackChunkName: "stock-adjustment-create" */ '../views/StockAdjustment/Form.vue'),
          },
        ],
      },
    ],
  },
]