<script setup>
import { ref, nextTick } from 'vue'
const emit = defineEmits(['opened', 'closed'])
const props = defineProps({
  canClickOutside: {
    type: Boolean,
    default: true,
  },
})

const display = ref(false)
const dim = ref(false)

const open = () => {
  dim.value = true
  nextTick(() => {
    display.value = true
  })
}
const close = () => {
  display.value = false
}
const afterLeave = () => {
  dim.value = false
  emit('closed')
}
const clickOutside = () => {
  if (props.canClickOutside) {
    close()
  }
}
defineExpose({ open, close })
</script>

<template>
  <teleport to="body">
    <div v-if="dim" class="fixed inset-0 z-50 flex items-center justify-center">
      <div class="fixed inset-0 bg-transparent-black" @click="clickOutside"></div>

      <transition
        name="modal"
        @after-leave="afterLeave"
        @after-enter="emit('opened')">
        <div v-if="display" class="fixed w-full max-w-lg px-4">
          <slot name="header"></slot>
          <slot name="content"></slot>
        </div>
      </transition>
    </div>
  </teleport>
</template>

<style lang="postcss" scoped>
  .modal-enter-active,
  .modal-leave-active {
      transition: opacity 0.3s ease, margin-top 0.3s ease;
  }
  .modal-enter-from,
  .modal-leave-to {
      margin-top: -50px;
      opacity: 0;
  }
</style>
