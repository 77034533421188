const state = () => ({
  logs: [
    // format log :
    // version => (string) version of app
    // date => (string date) date when version is released
    // update => (array of string) list of update
    // improvement => (array of string) list of improvement
    // dev => (array of string) list of change in technical language. for devs only
    {
      version: '1.03.00',
      date: '2023-11-09',
      update: [],
      improvement: [
        'Laporan penjualan hari ini hanya bisa diakses dengan pengguna yang memiliki hak akses',
      ],
      dev: [
        'Menambahkan pengecekan untuk menu laporan penjualan hari ini',
      ],
    },
    {
      version: '1.02.00',
      date: '2023-05-31',
      update: [
        'Menambahkan menu pengembalian mutasi eksternal',
      ],
      improvement: [
        'Update halaman tutorial dengan tampilan yang telah diperbarui',
      ],
      dev: [
        'Semua komponen vue sudah menggunakan setup script',
        'Tidak ada lagi request API yang menggunakan parameter request_get',
      ],
    },
    {
      version: '1.01.12',
      date: '2023-05-17',
      update: [],
      improvement: [
        'Fix mutasi sering kedobelan',
      ],
      dev: [],
    },
    {
      version: '1.01.11',
      date: '2023-05-17',
      update: [],
      improvement: [
        'Fix cetak mutasi dan terima mutasi',
      ],
      dev: [
        'Optimize script autocomplete box menggunakan setup script',
      ],
    },
    {
      version: '1.01.10',
      date: '2023-05-16',
      update: [],
      improvement: [
        'Minor fix tombol hapus penjualan yang tidak muncul',
        'Fix cek ongkos kirim',
      ],
      dev: [
        'Optimize script customer dan mutation menggunakan setup script',
      ],
    },
    {
      version: '1.01.09',
      date: '2023-05-02',
      update: [],
      improvement: [
        'Menambahkan daftar item yang sudah pernah diretur pada halaman detail penjualan',
        'Minor fix',
      ],
      dev: [],
    },
    {
      version: '1.01.08',
      date: '2023-04-14',
      update: [],
      improvement: [
        'Hapus fitur sync transaksi offline',
      ],
      dev: [],
    },
    {
      version: '1.01.07',
      date: '2023-04-06',
      update: [],
      improvement: [
        'Penyesuaian stok bisa memilih tanggal',
      ],
      dev: [],
    },
    {
      version: '1.01.06',
      date: '2023-04-03',
      update: [],
      improvement: [
        'Pembenahan laporan penjualan',
      ],
      dev: [],
    },
    {
      version: '1.01.05',
      date: '2023-03-24',
      update: [],
      improvement: [
        'Menambahkan informasi data yang ditampilkan apakah data cache',
        'Pembatalan transaksi bisa hingga h+1',
      ],
      dev: [],
    },
    {
      version: '1.01.04',
      date: '2023-03-21',
      update: [],
      improvement: [
        'Menambahkan tombol label pengiriman pada halaman cetak struk penjualan',
      ],
      dev: [],
    },
    {
      version: '1.01.03',
      date: '2023-03-20',
      update: [],
      improvement: [
        'Menambahkan tombol untuk tambah pengiriman pada transaksi penjualan',
      ],
      dev: [],
    },
    {
      version: '1.01.02',
      date: '2023-03-17',
      update: [],
      improvement: [
        'Menambahkan tombol untuk sinkronisasi transaksi dengan stok minus sampai 7 hari ke belakang',
        'Menambahkan kolom grup pada menu pencarian produk',
        'Input retur transaksi bisa sampai 14 hari yang lalu',
        'Input diskon per produk memerlukan password cabang',
      ],
      dev: [],
    },
    {
      version: '1.01.01',
      date: '2023-03-14',
      update: [],
      improvement: [
        'Optimasi sistem',
      ],
      dev: [
        'Update npm package dan service worker',
      ],
    },
    {
      version: '1.01.00',
      date: '2023-03-13',
      update: [
        'Menambahkan menu pengaturan toko',
        'Menambahkan transaksi stok opname dan riwayat transaksi stok opname',
        'Menambahkan dashboard',
        'Menambahkan stok produk',
      ],
      improvement: [
        'Form penyesuaian stok memiliki kolom sub total',
        'Halaman daftar mutasi memiliki kolom catatan pengirim',
      ],
      dev: [],
    },
    {
      version: '1.00.06',
      date: '2023-03-06',
      update: [],
      improvement: [
        'Free item tidak terhitung dalam jumlah item yang dibeli pada struk',
        'Fix perhitungan stok pada saat cari produk and tidak ikut sertakan stok kurang dari 1',
      ],
      dev: [
        'Fix menu dropdown yang blur nya ter-trigger duluan daripada click menunya',
      ],
    },
    {
      version: '1.00.05',
      date: '2023-03-04',
      update: [],
      improvement: [
        'Menambahkan header dan footer pada nota penjualan',
      ],
      dev: [
        'Update pembayaran penjualan bisa 3 hari ke belakang',
      ],
    },
    {
      version: '1.00.04',
      date: '2023-03-03',
      update: [],
      improvement: [
        'Waktu pengiriman dan penerimaan pada mutasi menampilkan jam dan menit',
        'Fix text alamat pengiriman pada label pengiriman penjualan',
      ],
      dev: [],
    },
    {
      version: '1.00.03',
      date: '2023-03-02',
      update: [],
      improvement: [
        'Pembenahan halaman yang tidak bisa diklik setelah halaman 2',
        'Pembenahan kalkulasi pada struk dengan biaya tambahan',
      ],
      dev: [
        'Set lower case pada kode produk saat scan konfirmasi mutasi',
      ],
    },
    {
      version: '1.00.02',
      date: '2023-03-02',
      update: [],
      improvement: [
        'Menambahkan kolom nama pada pencarian produk',
        'Menambahkan tipe mutasi pada struk mutasi',
        'Daftar barang pada label pengiriman disembunyikan',
      ],
      dev: [],
    },
    {
      version: '1.00.01',
      date: '2023-03-01',
      update: [],
      improvement: [
        'Menambahkan catatan pada struk penjualan',
        'Fix total pembayaran non tunai pada laporan penjualan hari ini',
      ],
      dev: [],
    },
    {
      version: '1.00.00',
      date: '2023-03-01',
      update: [],
      improvement: [
        'Menambahkan halaman bantuan untuk google authenticator',
        'Release The APP',
      ],
      dev: [
        'Ubah table dengan paginasi dari menggunakan menjadi menggunakan table',
        'Menambahkan permission baru dan limitasi beberapa fitur',
        'Menambahkan style untuk table dengan paginasi dan modal',
      ],
    },
    {
      version: '0.05.05',
      date: '2023-02-25',
      update: [],
      improvement: [
        'Ubah design label pengiriman penjualan',
      ],
      dev: [
        'Fix service worker untuk otomatis update',
      ],
    },
    {
      version: '0.05.04',
      date: '2023-02-23',
      update: [],
      improvement: [
        'Fix pengaturan layout cetak laporan',
      ],
      dev: [
        'Fix url notifikasi',
        'Menambahkan error message pada retur penjualan yang total qty sama dengan 0',
        'Fix style untuk produk tanpa harga jual',
      ],
    },
    {
      version: '0.05.03',
      date: '2023-02-20',
      update: [],
      improvement: [
        'Minor fix',
      ],
      dev: [
        'Fix bug saat print label penjualan',
        'Fix detail penjualan tanpa detil penjualan (hanya extra item saja)',
        'Fix detail pembayaran penjualan tanpa detil penjualan (hanya extra item saja)',
      ],
    },
    {
      version: '0.05.02',
      date: '2023-02-20',
      update: [],
      improvement: [
        'Pendaftaran member melalui kasir bisa tanpa menggunakan email',
        'Laporan penjualan hari ini memiliki kolom pembayaran non tunai',
      ],
      dev: [
        'Fix detail penjualan dan memiliki cashback',
        'Menambahkan label "required" pada form registrasi member dan input data pengiriman',
        'Input biaya tambahan pada penjualan tidak memerlukan password',
      ],
    },
    {
      version: '0.05.01',
      date: '2023-02-15',
      update: [],
      improvement: [
        'Perubahan style beberapa element',
      ],
      dev: [
        'Fix border input rounded pada halaman login',
        'Ubah style css notifikasi, input box, input image',
        'Ubah script layout menggunakan setup script',
      ],
    },
    {
      version: '0.05.00',
      date: '2023-02-08',
      update: [
        'Ubah tampilan halaman pembayaran transaksi',
      ],
      improvement: [
        'Diskon transaksi lebih responsif saat menambah item',
      ],
      dev: [
        'Ganti halaman transaksi menggunakan full setup script',
        'Ganti multiselect menggunakan custom komponen multiselect',
        'Fix diskon transaksi yang kurang responsif saat terjadi perubahan daftar item transaksi',
      ],
    },
    {
      version: '0.04.03',
      date: '2023-02-02',
      update: [],
      improvement: [
        'Minor fix',
        'Halaman mutasi eksternal memiliki filter status lunas',
        'Laporan mutasi dengan filter "dibatalkan" memiliki keterangan pembatalan',
        'Ubah format laporan mutasi eksternal agar sesuai dengan laporan komparasi mutasi eksternal',
      ],
      dev: [
        'Fix komponen password validation',
        'Fix pesan error pada form customer',
        'Fix css tinggi halaman print',
        'Tata ulang file dalam folder print sesuai dengan kategori',
        'Update sidebar dan menu menggunakan setup script',
      ],
    },
    {
      version: '0.04.02',
      date: '2023-01-27',
      update: [],
      improvement: [
        'Optimisasi sistem',
      ],
      dev: [
        'Buat komponen multiselect PIC',
        'Update komponen multiselect menggunakan style tailwind untuk error border',
        'Ganti komponen multiselect pada view menggunakan komponen multiselect pada folder Components',
        'Ubah formatting number untuk null dan 0',
        'Optimize icon menu dropdown biar keren :D',
        'Ubah menu dropdown menjadi komponen',
      ],
    },
    {
      version: '0.04.01',
      date: '2023-01-20',
      update: [],
      improvement: [
        'Minor fix',
      ],
      dev: [
        'Update komponen DatePicker',
        'Ubah parameter untuk request yang memiliki offset timezone, menjadi ISOString',
        'Pindahkan komponen AutocompleBox dan DatePicker ke dalam kategori components/Input',
      ],
    },
    {
      version: '0.04.00',
      date: '2023-01-18',
      update: [
        'Menambahkan keamanan ganda untuk akun',
        'Menambahkan fitur untuk sinkronisasi transaksi offline',
      ],
      improvement: [
        'Cetak transaksi offline tersedia pada halaman riwayat',
      ],
    },
    {
      version: '0.03.03',
      date: '2022-12-12',
      update: [],
      improvement: [
        'Update halaman bantuan untuk fitur yang sudah perbarui',
        'Fix minor bug',
      ],
    },
    {
      version: '0.03.02',
      date: '2022-12-12',
      update: [],
      improvement: [
        'Tampilan baru untuk halaman dengan filter',
      ],
    },
    {
      version: '0.03.01',
      date: '2022-12-12',
      update: [],
      improvement: [
        'Pembenahan desain halaman',
        'Pembenahan kesalahan cetak laporan stok opname',
      ],
    },
    {
      version: '0.03.00',
      date: '2022-12-01',
      update: [
        'Laporan kartu stok',
        'Cetak data struk penyesuaian stok',
        'Pembayaran transaksi penjualan bisa input mesin EDC',
      ],
      improvement: [
        'Pembaruan tampilan beberapa halaman',
      ],
    },
    {
      version: '0.02.00',
      date: '2022-10-19',
      update: [
        'Cetak data customer',
        'Cetak data mutasi internal dan eksternal',
        'Cetak data penjualan',
        'Cetak data penyesuaian stok',
      ],
      improvement: [
        'Ukuran tabel lebih responsif',
      ],
    },
    {
      version: '0.01.02',
      date: '2022-10-19',
      update: [
        'Mutasi eksternal',
      ],
      improvement: [
        'Re-design tampilan transaksi',
      ],
    },
    {
      version: '0.0.02',
      date: '2022-09-20',
      update: [
        'Setting password cabang',
      ],
      improvement: [
        'Stok opname bisa input qty',
        'Input mutasi bisa input kode yang sama, akan menambah qty kode yang sudah diinputkan',
        'Optimisasi halaman',
      ],
    },
    {
      version: '0.0.02',
      date: '2022-08-08',
      update: [],
      improvement: ['Judul setiap halaman responsif sesuai dengan menunya'],
    },
    {
      version: '0.0.01',
      date: '2022-08-08',
      update: ['Initial release'],
      improvement: [],
    },
  ],
})

const getters = {
  getlogs (state) {
    return state.logs
  },
}

export default {
  namespaced: true,
  state,
  getters,
}