import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueLazyLoad from 'vue3-lazyload'
import Pusher from 'pusher-js'
import VueQrcode from '@chenfengyuan/vue-qrcode'

// import './firebase'
// import sentry from './sentry'

// global stuffs
import globalComponents from './components'
import globalDirectives from './directives'

// styles
import './assets/tailwind.css'

// helper functions
import Api from './functions/api.js'
import RandomGenerator from './functions/randomGenerator.js'
import formatter from './functions/formatters'
import { registerSW } from 'virtual:pwa-register'
registerSW({ immediate: true })

const app = createApp(App)
app.config.globalProperties.fmt = formatter
app.config.globalProperties.api = new Api(router)
app.config.globalProperties.randGenerator = new RandomGenerator()
app.config.globalProperties.pusher = new Pusher(import.meta.env.PUSHER_APP_KEY, {
  cluster: import.meta.env.PUSHER_APP_CLUSTER,
  encrypted: true,
})
app.config.performance = true

app.component(VueQrcode.name, VueQrcode)
app.use(store)
  .use(router)
  .use(globalComponents)
  .use(globalDirectives)
  .use(VueLazyLoad)

app.mount('#app')

import Sentry from './sentry'
Sentry(app, router)

// sentry.init(app)