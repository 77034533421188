export default [
  {
    path: 'stockopname',
    children: [
      {
        path: '',
        name: 'StockopnameIndex',
        meta: { title: 'Stok Opname' },
        component: () => import(/* webpackChunkName: "stockopname" */ '../views/Stockopname/Index.vue'),
      },
      {
        path: 'submit/:stockopname_submit_id',
        name: 'StockopnameSubmitView',
        meta: { title: 'Stok Opname - Tampilkan Submit Stok Opname' },
        component: () => import(/* webpackChunkName: "stockopname" */ '../views/Stockopname/ViewSubmit.vue'),
      },
    ],
  },
  {
    path: '/stockopname-history',
    children: [
      {
        path: '',
        name: 'StockopnameHistory',
        meta: { title: 'Riwayat Stok Opname' },
        component: () => import(/* webpackChunkName: "stockopname" */ '../views/Stockopname/History.vue'),
      },
      {
        path: ':stockopname_id',
        name: 'StockopnameHistoryView',
        meta: { title: 'Stok Opname - Tampilkan Stok Opname' },
        component: () => import(/* webpackChunkName: "stockopname" */ '../views/Stockopname/View.vue'),
      },
      {
        path: 'submit/:stockopname_submit_id',
        name: 'StockopnameHistorySubmitView',
        meta: { title: 'Stok Opname - Tampilkan Submit Stok Opname' },
        component: () => import(/* webpackChunkName: "stockopname" */ '../views/Stockopname/ViewSubmit.vue'),
      },
    ],
  },
  {
    path: '/stockopname-sale',
    children: [
      {
        path: '',
        name: 'StockopnameSale',
        meta: { title: 'Riwayat Transaksi Stok Opname' },
        component: () => import(/* webpackChunkName: "stockopname-sale" */ '../views/Stockopname/Sale/Index.vue'),
      },
      {
        path: ':stockopname_sale_id',
        name: 'StockopnameSaleView',
        meta: { title: 'Transaksi Stok Opname - Tampilkan' },
        component: () => import(/* webpackChunkName: "stockopname-sale" */ '../views/Stockopname/Sale/View.vue'),
      },
    ],
  },
  // {
  //   path: '/stockopname-sale-payment',
  //   children: [
  //     {
  //       path: '',
  //       name: 'StockopnameSalePayment',
  //       meta: { title: 'Pembayaran Transaksi Stok Opname' },
  //       component: () => import(/* webpackChunkName: "stockopname-sale-payment" */ '../views/Stockopname/SalePayment/Index.vue'),
  //     },
  //     {
  //       path: ':stockopname_id',
  //       name: 'StockopnameSalePaymentView',
  //       meta: { title: 'Pembayaran Transaksi Stok Opname - Tampilkan' },
  //       component: () => import(/* webpackChunkName: "stockopname-sale-payment" */ '../views/Stockopname/SalePayment/View.vue'),
  //     },
  //   ],
  // },
  {
    path: 'transaction-stockopname',
    name: 'StockopnameSaleTransaction',
    meta: { title: 'Transaksi Stok Opname' },
    component: () => import(/* webpackChunkName: "stockopname-sale" */ '../views/StockopnameTransaction/Index.vue'),
  },
]