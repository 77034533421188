<template>
  <div class="flex flex-col pt-24 pb-12 items-center justify-center space-y-16">
    <div>
      <fa-layers class="text-red-500">
        <fa-icon
          :icon="['far', 'file-chart-column']"
          class="text-black"
          transform="left-3"
          size="7x" />
        <fa-icon :icon="['far', 'circle-question']" transform="left-5 up-5" size="4x" />
        <fa-icon :icon="['far', 'magnifying-glass']" transform="left-4 up-3" size="5x" />
      </fa-layers>
    </div>
    <div class="text-lg font-medium">Tidak ditemukan data. Coba gunakan filter lain</div>
  </div>
</template>