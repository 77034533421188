import axios from 'axios'

const state = () => ({
  startedYear: 2019,
  months: ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'],
  branches: [],
  edcMachines: [],
  externalLocations: [],
  paymentMethods: [],
  personInCharges: [],
  productGroups: [],
  shippers: [],
  shippersWithCode: [],
})

const getters = {
  getListOfYear (state) {
    let optionsYear = []
    for (let year = (new Date().getFullYear()); year >= state.startedYear; year--) {
      optionsYear.push(
        {
          value: year,
          label: year,
        })
    }
    return optionsYear
  },
  getListOfMonth (state) {
    return state.months.map((month, index) => {
      return {
        value: index + 1,
        label: month,
      }
    })
  },
  getListSaleType () {
    return [
      {
        value: 'retail',
        label: 'Tipe Retail',
      },
      {
        value: 'online',
        label: 'Tipe Online',
      },
    ]
  },
  getBranches (state) {
    return state.branches
  },
  getEdcMachines (state) {
    return state.edcMachines
  },
  getExternalLocations (state) {
    return state.externalLocations
  },
  getPaymentMethods (state) {
    return state.paymentMethods
  },
  getPersonInCharges (state) {
    return state.personInCharges
  },
  getProductGroups (state) {
    return state.productGroups
  },
  getShippers (state) {
    return state.shippers
  },
  getShippersWithCode (state) {
    return state.shippersWithCode
  },
}

const actions = {
  async fetchBranches ({ commit }) {
    try {
      const response = await axios.get(
        import.meta.env.VITE_URL_API + '/fetch/branch',
      )
      const branches = response.data.map((item) => {
        return { value: item.id, label: item.name, object: item }
      })
      commit('setBranches', branches)
    } finally { }
  },
  async fetchEdcMachines ({ commit, rootState }) {
    try {
      const response = await axios.get(
        import.meta.env.VITE_URL_API + '/fetch/edcMachine?branch=' + rootState.branch.id,
      )
      const edcMachines = response.data.map((item) => {
        return { value: item.id, label: item.name, object: item }
      })
      commit('setEdcMachines', edcMachines)
    } finally { }
  },
  async fetchExternalLocations ({ commit }) {
    try {
      const response = await axios.get(
        import.meta.env.VITE_URL_API + '/fetch/externalLocation',
      )
      const externalLocations = response.data.map((item) => {
        return { value: item.id, label: item.name, object: item }
      })
      commit('setExternalLocations', externalLocations)
    } finally {}
  },
  async fetchPaymentMethods ({ commit, rootState }) {
    try {
      const response = await axios.get(
        import.meta.env.VITE_URL_API + '/fetch/paymentMethod?visibility=shop&branch=' + rootState.branch.id,
      )
      const paymentMethods = response.data.filter(item => item.name !== 'Coin').map((item) => {
        return {
          value: item.id,
          label: item.label,
          visibilities: item.visibilities.map(item => item.visibility),
          object: item,
          disabled: false,
        }
      })
      commit('setPaymentMethods', paymentMethods)
    } finally { }
  },
  async fetchPersonInCharges ({ commit, rootState }) {
    try {
      const response = await axios.get(
        import.meta.env.VITE_URL_API + '/fetch/cashier?active=true&branch=' + rootState.branch.id,
      )
      const personInCharges = response.data.map((item) => {
        return { value: item.user_id, label: item.user.name, object: item.user }
      })
      commit('setPersonInCharges', personInCharges)
    } finally { }
  },
  async fetchProductGroups ({ commit }) {
    try {
      const response = await axios.get(
        import.meta.env.VITE_URL_API + '/fetch/productGroup',
      )
      const productGroups = response.data.map((item) => {
        return { value: item.id, label: item.name, object: item }
      })
      commit('setProductGroups', productGroups)
    } finally {}
  },
  async fetchShippers ({ commit }) {
    try {
      const response = await axios.get(
        import.meta.env.VITE_URL_API + '/fetch/shipper',
      )
      const shippers = response.data.map((item) => {
        return { value: item.id, label: item.name }
      })
      commit('setShippers', shippers)
    } finally { }
  },
  async fetchShippersWithCodeOnly ({ commit }) {
    try {
      const response = await axios.get(
        import.meta.env.VITE_URL_API + '/fetch/shipper?code=true',
      )
      const shippers = response.data.map((item) => {
        return { value: item.code, label: item.name }
      })
      commit('setShippersWithCode', shippers)
    } finally { }
  },
}

const mutations = {
  setBranches (state, branches) {
    state.branches = branches
  },
  setEdcMachines (state, edcMachines) {
    state.edcMachines = edcMachines
  },
  setExternalLocations (state, externalLocations) {
    state.externalLocations = externalLocations
  },
  setPaymentMethods (state, paymentMethods) {
    state.paymentMethods = paymentMethods
  },
  setPersonInCharges (state, personInCharges) {
    state.personInCharges = personInCharges
  },
  setProductGroups (state, productGroups) {
    state.productGroups = productGroups
  },
  setShippers (state, shippers) {
    state.shippers = shippers
  },
  setShippersWithCode (state, shippers) {
    state.shippersWithCode = shippers
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}