import { createRouter, createWebHistory } from 'vue-router'

import account from './account'
import authRoutes from './auth'
import customer from './customer'
import mutation from './mutation'
import printRoutes from './printPage'
import sale from './sale'
import stock from './stock'
import stockopname from './stockopname'

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../layouts/Dashboard/Index.vue'),
    children: [
      {
        path: '/',
        name: 'Transaction',
        meta: { title: 'Transaksi' },
        component: () => import(/* webpackChunkName: "transaction" */ '../views/Transaction/Index.vue'),
      },
      {
        path: '/Admin',
        name: 'Home',
        meta: { title: 'Admin' },
        component: () => import(/* webpackChunkName: "transaction" */ '../views/Dashboard/Home.vue'),
      },
      {
        path: '/forbidden',
        name: '403',
        meta: { title: 'Halaman Tidak Bisa Diakses' },
        component: () => import(/* webpackChunkName: "errors" */ '../views/Error/403.vue'),
      },
      {
        path: '/:pathMatch(.*)*',
        name: '404',
        meta: { title: 'Halaman Tidak Ditemukan' },
        component: () => import(/* webpackChunkName: "errors" */ '../views/Error/404.vue'),
      },
      {
        path: '/maintenance',
        name: '503',
        meta: { title: 'Server Dalam Perbaikan' },
        component: () => import(/* webpackChunkName: "errors" */ '../views/Error/503.vue'),
      },
      {
        path: '/no-branch',
        name: 'NoBranch',
        meta: { title: 'Cabang Tidak Ditemukan' },
        component: () => import(/* webpackChunkName: "errors" */ '../views/Error/NoBranch.vue'),
      },
      {
        path: '/shipper/rate',
        name: 'ShippingRate',
        meta: { title: 'Cek Ongkir' },
        component: () => import(/* webpackChunkName: "shipper-shipping-rate" */ '../views/Shipper/ShippingRate.vue'),
      },
      {
        path: '/report/today-transaction',
        name: 'ReportTodayTransaction',
        meta: { title: 'Laporan Transaksi Hari Ini' },
        component: () => import(/* webpackChunkName: "report-today-transaction" */ '../views/Report/TodayTransaction.vue'),
      },
      {
        path: '/report/stockopname',
        name: 'ReportStockopname',
        meta: { title: 'Laporan Stok Opname' },
        component: () => import(/* webpackChunkName: "report-stockopname" */ '../views/Report/Stockopname/index.vue'),
      },
      {
        path: '/report/stock-carc',
        name: 'ReportStockCard',
        meta: { title: 'Laporan Kartu Stok' },
        component: () => import(/* webpackChunkName: "report-stockopname" */ '../views/Report/StockCard/index.vue'),
      },
      ...account,
      ...customer,
      ...mutation,
      ...sale,
      ...stock,
      ...stockopname,
    ],
  },
  authRoutes,
  printRoutes,
  {
    path: '/help',
    name: 'Help',
    meta: { title: 'Bantuan' },
    component: () => import(/* webpackChunkName: "help" */ '../views/Help/Index.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next()

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta')

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key])
    })

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '')

    return tag
  })
  // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag))

  next()
})

export default router
