const state = () => ({
  errors: {},
  stage: 1,
  pic: {
    id: null,
    name: '',
    code: '',
    hasPic: false,
  },
  items: [],
  returs: [],
  discount: {
    percentage: 0,
    nominal: 0,
  },
  payments: [],
})

const getters = {
  getStage (state) {
    return state.stage
  },
  getErrors (state) {
    return state.errors
  },
  getPic (state) {
    return state.pic
  },
  getDiscount (state) {
    return state.discount
  },

  // purchase item
  getItems (state) {
    return state.items
  },
  getItemByItem: (state) => (index) => {
    if (index) {
      return state.items[index]
    }
    return null
  },
  getTotalPurchase (state) {
    let total = 0
    state.items.forEach(item => {
      total += (item.price - item.discountNominal) * item.qty
    })
    return total
  },
  getTotalItem (state) {
    let total = 0
    state.items.forEach(item => {
      if (item.price > 0) {
        total += parseInt(item.qty)
      }
    })
    return total
  },
  getTotalFreeItem (state) {
    let total = 0
    state.items.forEach(item => {
      if (item.price === 0) {
        total += parseInt(item.qty)
      }
    })
    return total
  },

  //retur item
  getReturs (state) {
    return state.returs
  },
  getTotalReturPrice (state) {
    let total = 0
    state.returs.forEach(item => {
      total += item.price * item.qty
    })
    return total
  },
  getTotalRetur (state) {
    let total = 0
    state.returs.forEach(item => {
      total += parseInt(item.qty)
    })
    return total
  },

  //discount
  getDiscountNominal (state) {
    return parseInt(state.discount.nominal)
  },
  getDiscountPercentage (state) {
    return parseInt(state.discount.percentage)
  },

  //payments
  getPayments (state) {
    return state.payments
  },
  getPaymentByIndex: (state) => (index) => {
    return state.payments[index]
  },
  getTotalPayment (state) {
    let total = 0
    state.payments.forEach(element => {
      total += element.nominal ? parseInt(element.nominal) : 0
    })
    return total
  },
}

const actions = {
  resetCurrentVariables ({ commit }) {
    commit('resetVariables')
  },
  changeStage ({ commit }, stage) {
    commit('setStage', stage)
    commit('setErrors', {})
  },
  changeErrors ({ commit }, errors) {
    commit('setErrors', errors)
  },
  addPic ({ commit }, pic) {
    commit('inputPic', pic)
  },
  removePic ({ commit }) {
    commit('resetPic')
  },
  // action discount
  addDiscount ({ commit }, discount) {
    commit('inputDiscount', discount)
  },
  updateDiscount ({ state, commit, getters }) {
    if (state.discount.percentage > 0) {
      const discount = {
        percentage: state.discount.percentage,
        nominal: parseFloat(getters.getTotalPurchase * state.discount.percentage / 100),
      }
      commit('inputDiscount', discount)
    }
  },
  // action item
  addNewItem ({ commit }, newItem) {
    commit('addItem', newItem)
  },
  increaseItemQty ({ commit }, index) {
    commit('updateQty', { index: index, qty: 1 })
  },
  decreaseItemQty ({ state, commit }, index) {
    if (state.items[index].qty > 1) {
      commit('updateQty', { index: index, qty: -1 })
    }
  },
  deleteItem ({ commit }, index) {
    commit('deleteItemByIndex', index)
  },
  // action retur item
  addNewRetur ({ commit }, newRetur) {
    commit('addRetur', newRetur)
  },
  increaseReturQty ({ commit }, index) {
    commit('updateReturQty', { index: index, qty: 1 })
  },
  decreaseReturQty ({ state, commit }, index) {
    if (state.returs[index].qty > 1) {
      commit('updateReturQty', { index: index, qty: -1 })
    }
  },
  deleteReturItem ({ commit }, index) {
    commit('deleteReturByIndex', index)
  },
  // action payment
  addNewPayment ({ commit }, param) {
    commit('addPayment', param)
  },
  updatePaymentByIndex ({ commit }, param) {
    commit('updatePayment', param)
  },
  deletePaymentByIndex ({ commit }, index) {
    commit('deletePayment', index)
  },
  deleteAllPayments ({ commit }) {
    commit('deletePayments')
  },
  transactionSuccess ({ commit }) {
    commit('resetVariables')
    commit('setErrors', {})
    commit('setStage', 1)
  },
}

const mutations = {
  setVariables (state, params) {
    state.stage = params.stage
    state.pic = params.pic
    state.items = params.items
    state.returs = params.returs
    state.discount = params.discount
    state.payments = params.payments
  },
  resetVariables (state) {
    state.stage = 1
    state.errors = {}
    state.pic = {
      id: null,
      name: '',
      code: '',
      hasPic: false,
    }
    state.items = []
    state.returs = []
    state.discount = {
      percentage: 0,
      nominal: 0,
    }
    state.payments = []
  },
  setStage (state, stage) {
    state.stage = stage
  },
  setErrors (state, errors) {
    state.errors = errors
  },
  // mutation pic
  inputPic (state, pic) {
    state.pic = pic
  },
  resetPic (state) {
    state.pic = {
      id: null,
      name: '',
      code: '',
      hasPic: false,
    }
  },
  inputDiscount (state, discount) {
    state.discount = discount
  },
  // mutation item
  addItem (state, newItem) {
    if (state.items.filter(item => item.id === newItem.id || item.productCode === newItem.productCode).length) {
      state.items.filter(item => item.id === newItem.id || item.productCode === newItem.productCode)[0].qty++
    } else {
      state.items.push(newItem)
    }
  },
  updateQty (state, params) {
    state.items[params.index].qty += params.qty
  },
  updateDiscountItem (state, params) {
    state.items[params.index].discountNominal = params.nominal
    state.items[params.index].discountPercentage = params.percentage
  },
  deleteItemByIndex (state, index) {
    state.items.splice(index, 1)
  },
  // mutation retur
  addRetur (state, newRetur) {
    if (state.returs.filter(item => item.id === newRetur.id || item.productCode === newRetur.productCode).length) {
      state.returs.filter(item => item.id === newRetur.id || item.productCode === newRetur.productCode)[0].qty++
    } else {
      state.returs.push(newRetur)
    }
  },
  updateReturQty (state, params) {
    state.returs[params.index].qty += params.qty
  },
  deleteReturByIndex (state, index) {
    state.returs.splice(index, 1)
  },
  // mutation payment
  addPayment (state, param) {
    state.payments.push({
      name: param?.paymentMethod?.label ?? 'Pilih Metode',
      nominal: param.nominal ?? 0,
      senderAccountName: '',
      senderAccountNumber: '',
      receiverAccountName: '',
      receiverAccountNumber: '',
      note: '',
      paymentMethodId: param?.paymentMethod?.id,
      edcMachineId: null,
    })
  },
  deletePayment (state, index) {
    state.payments.splice(index, 1)
  },
  deletePayments (state) {
    state.payments = []
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}