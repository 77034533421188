export default {
  path: '/',
  component: () => import(/* webpackChunkName: "report" */ '../layouts/Print/Index.vue'),
  children: [
    {
      path: '/customer/print',
      name: 'CustomerPrint',
      meta: { title: 'Cetak Data Customer' },
      component: () => import(/* webpackChunkName: "customer-print" */ '../views/Print/MasterData/Customer.vue'),
    },
    {
      path: '/stock-card/print',
      name: 'StockCardReportPrint',
      meta: { title: 'Cetak Laporan Kartu Stok' },
      component: () => import(/* webpackChunkName: "stock-card-report-print" */ '../views/Print/Stock/StockCardReport.vue'),
    },
    {
      path: '/stockopname/print',
      name: 'StockopnamePrint',
      meta: { title: 'Cetak Laporan Stok Opname' },
      component: () => import(/* webpackChunkName: "stockopname-print" */ '../views/Print/Stock/Stockopname.vue'),
    },
    {
      path: '/stockopname-receipt/print',
      name: 'StockopnameReceiptPrint',
      meta: { title: 'Cetak Struk Penjualan Stok Opname' },
      component: () => import(/* webpackChunkName: "stockopname-print" */ '../views/Print/StockopnameSale/Receipt.vue'),
    },
    {
      path: '/transaction/receipt',
      name: 'ReceiptPrint',
      meta: { title: 'Cetak Struk' },
      component: () => import(/* webpackChunkName: "print-receipt" */ '../views/Print/Sale/Receipt.vue'),
    },
    {
      path: '/stock/print',
      name: 'StockPrint',
      meta: { title: 'Cetak Laporan Master Produk' },
      component: () => import(/* webpackChunkName: "stock-print" */ '../views/Print/Stock/Index.vue'),
    },
    {
      path: '/stock-adjustment',
      children: [
        {
          path: ':stock_adjustment_id/print',
          name: 'StockAdjustmentReceiptPrint',
          meta: { title: 'Cetak Struk Penyesuaian Stok' },
          component: () => import(/* webpackChunkName: "stock-adjustment-receipt-print" */ '../views/Print/Stock/StockAdjustmentReceipt.vue'),
        },
      ],
    },
    {
      path: '/mutation',
      children: [
        {
          path: ':mutation_id/print-receipt',
          name: 'MutationInvoicePrintReceipt',
          meta: { title: 'Cetak Struk Mutasi' },
          component: () => import(/* webpackChunkName: "mutation-invoice-print-receipt" */ '../views/Print/Mutation/Internal/InvoiceReceipt.vue'),
        },
      ],
    },
    {
      path: '/mutation-external',
      children: [
        {
          path: ':mutation_external_id/print-receipt',
          name: 'MutationExternalInvoicePrintReceipt',
          meta: { title: 'Cetak Struk Mutasi Eksternal' },
          component: () => import(/* webpackChunkName: "mutation-invoice-print-receipt" */ '../views/Print/Mutation/External/InvoiceReceipt.vue'),
        },
      ],
    },
    {
      path: '/shipping',
      children: [
        {
          path: 'sale/print',
          name: 'SaleShippingLabelPrint',
          meta: { title: 'Cetak Label Pengiriman' },
          component: () => import(/* webpackChunkName: "print-sale-shipping" */ '../views/Print/Label/Shipping.vue'),
        },
        {
          path: 'sale/print-mini',
          name: 'SaleShippingLabelPrintReceipt',
          meta: { title: 'Cetak Label Pengiriman' },
          component: () => import(/* webpackChunkName: "print-sale-shipping-receipt" */ '../views/Print/Label/ShippingReceipt.vue'),
        },
      ],
    },
  ],
}