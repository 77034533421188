import FontAwesome from './FontAwesome'
import Pagination from './Pagination.vue'
import Modal from './Modal/Index.vue'
import ErrorMsg from './ErrorMessage.vue'
import ModalLoading from './Modal/Loading.vue'
import ModalConfirmation from './Modal/Confirmation.vue'
import ModalDeleteConfirmation from './Modal/DeleteConfirmation.vue'
import DataTableSeparator from './DataTableSeparator.vue'
import Button from './Button/Index.vue'
import GNumeric from './Input/Numeric.vue'
import GLabel from './Label.vue'
import FilterSearch from './Filter/Search.vue'
import GTable from './Table/Paginated.vue'

export default {
  install (Vue) {
    Vue.component('PaginationData', Pagination)
    Vue.component('ModalComponent', Modal)
    Vue.component('GErrorMsg', ErrorMsg)
    Vue.component('ModalLoading', ModalLoading)
    Vue.component('ModalConfirmation', ModalConfirmation)
    Vue.component('ModalDeleteConfirmation', ModalDeleteConfirmation)
    Vue.component('TableSeparator', DataTableSeparator)
    Vue.component('GButton', Button)
    Vue.component('GNumeric', GNumeric)
    Vue.component('GLabel', GLabel)
    Vue.component('FilterSearch', FilterSearch)
    Vue.component('GTable', GTable)
    Vue.use(FontAwesome)
  },
}